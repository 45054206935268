import { useAtomValue } from 'jotai';
import Image from 'next/image';
import { MyLink, MyLinkProps } from 'src/components/core/mine/my-link';
import { logoAtom } from 'src/store/contentful';

interface LogoProps extends Partial<MyLinkProps> {
  svgProps?: React.SVGProps<SVGSVGElement>;
}

export const Logo: React.FCC<LogoProps> = ({ svgProps, ...linkProps }) => {
  const logo = useAtomValue(logoAtom);

  const img = (
    <Image
      src={logo}
      quality={100}
      alt="UBET-logo"
      priority
      fetchPriority="high"
      width={100}
      height="0"
      style={{ width: '100px', height: 'auto' }}
    />
  );

  if (linkProps.href) {
    return (
      <MyLink
        {...linkProps}
        sx={{ lineHeight: 0, ...linkProps?.sx }}
        href={linkProps.href}>
        {img}
      </MyLink>
    );
  }

  return img;
};
