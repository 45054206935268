import { QueryMapping } from 'src/hooks/use-breakpoint';
import { makeStyles } from 'tss-react/mui';

type Props = {
  isCanceled: boolean;
};

export const useStyles = makeStyles<Props>()((theme, { isCanceled }) => {
  const mobile = QueryMapping.mobile;

  return {
    BoxMobile: {
      display: 'none',
      [mobile]: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        borderTop: `1px solid ${theme.palette.divider}`,
      },
      color: isCanceled ? theme.custom.content[3] : theme.custom.content[1],
    },

    inforCompetitor: {
      display: 'flex',
      marginTop: 5,
      '& > p': {
        marginLeft: theme.spacing(1),
      },
      '& img': {
        opacity: isCanceled ? '.3' : '1',
      },
    },
    marginT4: {
      marginTop: theme.spacing(0.5),
    },
    fontBold: {
      fontWeight: 500,
      color: isCanceled ? 'inherit' : theme.custom.content[1],
    },
    marginT8: {
      marginTop: theme.spacing(1),
    },
    marginT24: {
      marginTop: 24,
    },
    marginB10: {
      marginBottom: theme.spacing(1.25),
    },
    status: {
      fontWeight: 'bold',
      fontSize: 12,
      textTransform: 'uppercase',
      padding: theme.spacing(0.375, 0.625),
      borderRadius: 3,
      lineHeight: 'normal',
      width: 'fit-content',
      letterSpacing: 0.4,
    },
    spacingBetween: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    marginV4: {
      margin: theme.spacing(0.5, 0),
    },
  };
});
