import _ from 'lodash';
import moment from 'moment';

export const convertTimeBetISO = (
  input: string | number,
  // eslint-disable-next-line lingui/no-unlocalized-strings
  formatString = 'ddd - D/M/YY @ h:mma',
) => {
  return moment(input).format(formatString);
};

// eslint-disable-next-line lingui/no-unlocalized-strings
export const convertTime = (
  value: string | number,
  formatString = 'D/M/YY @ h:mma',
) => {
  // if timestamp in seconds
  if (_.isNumber(value) && value.toString().length === 10) {
    return moment(value * 1000).format(formatString);
  }
  return moment(value).format(formatString);
};

const getTimezoneOffset = (value: Date) => value.getTimezoneOffset() * 60000;

export const makeLocalAppearUTC = (_value?: any) => {
  const value = moment(_value);
  if (!value.isValid()) return _value;

  const dateTime = value.toDate();
  const utcFromLocal = new Date(
    dateTime.getTime() + getTimezoneOffset(dateTime),
  );
  return utcFromLocal;
};

export const localToUTC = (_value?: any) => {
  const value = moment(_value);
  if (!value.isValid()) return _value;

  const dateTime = value.toDate();

  const utcFromLocal = new Date(
    dateTime.getTime() - getTimezoneOffset(dateTime),
  );
  return utcFromLocal;
};
