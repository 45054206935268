import { match } from 'path-to-regexp';
import { DEFAULT_LOCALE, LOCALES } from '../constants/keys';

const localeExtractor = match<{ locale?: string; pathname: string }>(
  `/:locale(${LOCALES.join('|')})?:pathname(.*)?`,
);

const cache: Record<string, { locale: string; pathname: string }> = {};
const cacheLimit = 1000;
let cacheCount = 0;

export const extractLocale = (pathname: string) => {
  if (cache[pathname]) return cache[pathname];

  const matched = localeExtractor(pathname);
  const params = matched ? matched.params : undefined;

  const res = {
    locale: params?.locale ?? DEFAULT_LOCALE,
    pathname: params?.pathname ?? '/',
  };

  if (cacheCount < cacheLimit) {
    cache[pathname] = res;
    cacheCount++;
  }
  return res;
};
