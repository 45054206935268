import {
  BaseEvent,
  EventCompetitors,
  Tournament,
  TvChannel,
} from 'src/utils/types/event';
import { MyBetStatus } from 'src/utils/types/my-bet-type';
import { BaseOutcome } from 'src/utils/types/outcome';
import { BrMarketStatus } from '../constants/common-constant';
import { SocketEvent } from '../constants/socket-event';
import { TournamentRank } from './tournament';

interface StatisticItem {
  _home?: string;
  _away?: string;
}

interface Statistics {
  corners?: StatisticItem;
  yellow_cards?: StatisticItem;
  green_cards?: StatisticItem;
  red_cards?: StatisticItem;
  yellow_red_cards?: StatisticItem;
}

interface Clock {
  _match_time: string;
  _remaining_time: string;
  _remaining_time_in_period: string;
}

interface Score {
  _home_score?: string;
  _away_score?: string;
  _match_status_code: string;
}
interface PeriodScore extends Score {
  _number: string;
  _type: string;
}

interface Result extends Score {}

interface SportEventStatus {
  _status: string;
  _home_score?: string;
  _away_score?: string;
  _match_status?: string;
  _home_gamescore?: string;
  _away_gamescore?: string;
  _home_penalty_score?: string;
  _away_penalty_score?: string;

  statistics?: Statistics;
  clock?: Clock;
  period_scores?: {
    period_score: PeriodScore[] | PeriodScore;
  };
  results?: Result[];
}

interface Outcome {
  _id: string;
  _odds: string;
  _probabilities?: string;
  _active: string;
  _result?: string;
  _void_factor?: string;
}

interface Market {
  _id: string;
  _specifiers?: string;
  _status?: BrMarketStatus;
  outcome?: Outcome[] | Outcome;
}

export interface SportLatestBet {
  color: string;
  logo: string;
  logoName: string;
  name: string;
  sportId: string;
  _id: string;
}

export interface TournamentScoreMessage extends TournamentRank {
  tournament: string;
}

export interface MyBetMessage {
  alias: string;
  id: string;
  odds: string;
  payout: string;
  placeTotalOdds: string;
  stake: string;
  status: MyBetStatus;
  type: string;
  userId: number;
  alternativeStake?: string;
  roomType: RoomType;
  error?: {
    code: number;
    detail: Record<MyBetMessage['alias'], number>;
  };
}

export interface MyBetCasinoMessage {
  gameIdentifier: string;
  createdAt: string;
  gameTitle?: string;
  gameCategory?: string;
  multiplier: string;
  payout: string;
  roomId: string;
  stake: string;
  ticketId: string;
  username: string;
  isFreespin: boolean;
}

export enum RoomType {
  SYMBOL = 'SYMBOL',
  PROMOTION_CAMPAIGN = 'PROMOTION_CAMPAIGN',
}

export enum ELatestBetType {
  SINGLE = 'SINGLE',
  MULTI = 'MULTI',
}

export interface LatestBetMessage extends EventCompetitors {
  betId: string;
  id: string;
  createdAt: string;
  event: string;
  odds: string;
  sport: SportLatestBet;
  stake: string;
  type: ELatestBetType;
  username: string;
  isUpcoming?: boolean;
  logo?: string;
  logoName?: string;
  color?: string;
  roomId: string;
  roomType: RoomType;

  isOutRight?: boolean;
  placeTotalOdds?: string;
  rank?: string;
  name?: string;
  totalGames: number;
  tournament: Tournament;
  languages?: Record<string, ILanguageBet>;
  status: MyBetStatus;
}

export interface ILanguageBet extends EventCompetitors {
  sport: SportLatestBet;
  tournament: Tournament;
  marketOutcomes: BaseOutcome;
  name: string;
}

export interface LatestBetCasinoMessage {
  createdAt: string;
  gameIdentifier: string;
  gameTitle?: string;
  gameCategory?: string;
  username: string;
  stake: string;
  multiplier: string;
  payout: string;

  roomId: string;
  ticketId: string;
  rank: string;
  userId: number;
  languages?: {
    [key: string]: ILanguageBet;
  };
}

export declare module brEvent {
  export interface TournamentRound {
    type: string;
    // number: string;
    // groupLongName: string;
    betradarId: string;
    betradarName: string;
  }

  export interface Season {
    start_date: string;
    end_date: string;
    year: string;
    tournament_id: string;
    id: string;
    name: string;
  }

  export interface Sport {
    id: string;
    name: string;
  }

  export interface Category {
    id: string;
    name: string;
    country_code: string;
  }

  export interface Tournament {
    sport: Sport;
    category: Category;
    id: string;
    name: string;

    scheduled: string | null;

    scheduledEnd: string | null;
  }

  export interface ReferenceId {
    id: string;
    scheduled: string;
    startTimeTbd: string;
    nextLiveTime: string;
  }

  export interface Competitor {
    qualifier: string;
    id: string;
    name: string;
    abbreviation: string;
    shortName: string;
    country: string;
    countryCode: string;
    gender: string;
  }

  export interface Competitors {
    competitor: Competitor[];
  }

  export interface Info {
    _key: string;
    _value: string;
  }

  export interface Fixture {
    tournamentRound: TournamentRound;
    season: Season;
    tournament: Tournament;
    competitors: Competitor[];
    tvChannels?: TvChannel[];
    tv_channels?: TvChannel[];
    startTimeConfirmed: string;
    startTime: string;
    liveodds: string;
    status: string;
    id: string;
    scheduled: string;
    startTimeTbd: string;
    nextLiveTime: string;
  }
}

export module BetRadarV5 {
  export interface Base {
    _event_id: string;
    _timestamp: string;
    _product: string;
  }
  export interface OddChange extends Base {
    sport_event_status?: SportEventStatus;
    odds?: {
      market?: Market | Market[];
    };
  }
  export interface BetStop extends Base {
    _groups: string;
    _market_status: BrMarketStatus;
  }
  export interface FixtureChange extends Base {
    _event_id: string;
    _product: string;
    _timestamp: string;
    _start_time: string;
    _change_type: string;
  }
  export interface BetCancel extends Base {
    market?: Market | Market[];
    _start_time?: number;
    _end_time?: number;
  }
  export interface RollbackBetCancel {}
  export interface BetSettlement extends Base {
    outcomes: {
      market: Market | Market[];
    };
  }
  export interface RollbackBetSettlement {}

  export interface BrMarket extends Market {}
}

interface MyMapping extends Record<SocketEvent, any> {
  [SocketEvent.ODD_CHANGE_V5]: BetRadarV5.OddChange;
  [SocketEvent.BET_STOP_V5]: BetRadarV5.BetStop;
  [SocketEvent.FIXTURE_CHANGE_V5]: BetRadarV5.FixtureChange;
  [SocketEvent.BET_CANCEL_V5]: BetRadarV5.BetCancel;
  [SocketEvent.ROLLBACK_BET_CANCEL_V5]: BetRadarV5.RollbackBetCancel;
  [SocketEvent.BET_SETTLEMENT]: BetRadarV5.BetSettlement;
  [SocketEvent.ROLLBACK_BET_SETTLEMENT_V5]: BetRadarV5.RollbackBetSettlement;
}

type BaseMessageType<Event extends SocketEvent> = {
  type: SocketEvent;
  room: string;
  message: Event extends never
    ? any
    : MyMapping[Event] extends object
    ? MyMapping[Event]
    : any;
};
interface BrFixtureInfo {
  // only for event `fixture_change` - raw
  fixture_info: brEvent.Fixture;
  // tried to refine fixture
  fixture_refined: Partial<BaseEvent>;
}

export type BaseMessage<Event extends SocketEvent = any> =
  Event extends SocketEvent.FIXTURE_CHANGE_V5
    ? BaseMessageType<Event> & BrFixtureInfo
    : BaseMessageType<Event>;
