'use client';

import BigNumber from 'bignumber.js';
import { useSetAtom } from 'jotai';
import { type NavigateOptions } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { useRouter as useNextRouter, usePathname } from 'next/navigation';
import qs from 'qs';
import { useCallback, useMemo } from 'react';
import { localeAtom } from 'src/store/auth';
import { DEFAULT_LOCALE } from 'src/utils/constants/keys';
import { localizePathname } from 'src/utils/constants/path';
import { extractLocale } from 'src/utils/helpers/locale';

const LOCALE_FORMATS = new Map<string, BigNumber.Format>([
  ['en', { decimalSeparator: '.', groupSize: 3, groupSeparator: ',' }],
  ['pt-br', { decimalSeparator: ',', groupSize: 3, groupSeparator: '.' }],
  ['fr-ca', { decimalSeparator: '.', groupSize: 3, groupSeparator: ',' }],
]);

export const getBNFormat = (
  locale: string = DEFAULT_LOCALE,
): BigNumber.Format => {
  return (
    LOCALE_FORMATS.get(locale.toLowerCase()) ??
    LOCALE_FORMATS.get(DEFAULT_LOCALE) ??
    {}
  );
};

export const useMyPathname = () => {
  const localedPathname = usePathname();

  return useMemo(() => {
    return extractLocale(localedPathname);
  }, [localedPathname]);
};

export const useLocale = () => {
  const { pathname, locale } = useMyPathname();
  const setLocale = useSetAtom(localeAtom);

  const onLocaleChange = useCallback(
    (next: string) => {
      setLocale(next);
      window.location.href =
        localizePathname({ href: pathname, locale: next }) +
        window.location.search;
    },
    [pathname, setLocale],
  );
  const FORMAT = getBNFormat(locale);

  const { decimalSeparator } = FORMAT;

  return { locale, pathname, FORMAT, decimalSeparator, onLocaleChange };
};

interface MyRouter extends ReturnType<typeof useNextRouter> {}

interface MyNavigateOptions extends NavigateOptions {
  refresh?: boolean;
  query?: Record<string, any>;
}

export const withQuery = (href: string, query?: any) => {
  if (!query) return href;

  return `${href}${qs.stringify(query, { addQueryPrefix: true })}`;
};

export const useMyRouter = () => {
  const baseRouter = useNextRouter();
  const { pathname, locale } = useMyPathname();

  const push = useCallback(
    (href: string, opts?: MyNavigateOptions) => {
      const { query, refresh, ...options } = opts || {};
      const next = localizePathname({
        href: withQuery(href, query),
        locale: locale ?? DEFAULT_LOCALE,
      });
      baseRouter.push(next, options);

      if (refresh) {
        baseRouter.refresh();
      }
    },
    [baseRouter, locale],
  );

  const replace = useCallback(
    (href: string, opts?: MyNavigateOptions) => {
      const { query, refresh, ...options } = opts || {};
      const next = localizePathname({
        href: withQuery(href, query),
        locale: locale ?? DEFAULT_LOCALE,
      });
      baseRouter.push(next, options);

      if (refresh) {
        baseRouter.refresh();
      }
    },
    [baseRouter, locale],
  );

  return {
    ...baseRouter,
    locale,
    pathname,
    push,
    replace,
  };
};
