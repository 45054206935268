import { useMediaQuery } from '@mui/material';
import theme from 'src/theme';
import { isDeviceType, MyDevices } from 'src/utils/helpers/etc';

export const QueryMapping: Record<MyDevices, string> = {
  [MyDevices.DESKTOP]: theme.breakpoints.up('md'), // x > 768px
  [MyDevices.MOBILE]: theme.breakpoints.down('md'), // 0 < x < 768px
};

export const useBreakpoint = (device: MyDevices) => {
  const isTheDevice = useMediaQuery(QueryMapping[device]);

  return isTheDevice || isDeviceType(device);
};
