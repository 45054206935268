export enum SocketEvent {
  Identity = 'identity', // login
  UserBalance = 'user_balance', // wallet

  UserTransaction = 'user_transaction-v3',
  MyBets = 'my_bet-v3',
  PlacedBet = 'place_bet-v3',
  PlacedBetCasino = 'play-request-casino',
  CountLiveNow = 'count_live_now',
  ODD_CHANGE_V5 = 'odds_change',
  BET_STOP_V5 = 'bet_stop',
  FIXTURE_CHANGE_V5 = 'fixture_change',
  BET_CANCEL_V5 = 'bet_cancel',
  ROLLBACK_BET_CANCEL_V5 = 'rollback_bet_cancel',
  BET_SETTLEMENT = 'bet_settlement',
  ROLLBACK_BET_SETTLEMENT_V5 = 'rollback_bet_settlement',
  PlacedBetCasinoUser = 'play-request-casino-user',
  TournamentScore = 'update-tournament-score',
  KYC = 'kyc_change',
  Logout = 'clear-all-token',
  VERIFY_EMAIL_SUCCESS = 'email_verify_success',
  PROFILE_VERIFY_SUCCESS = 'profile_verify_success',
}
