import _ from 'lodash';
import { ProfileData } from 'src/utils/types/auth';
import {
  BaseEvent,
  ICategory,
  ITournament,
  SportEvent,
} from 'src/utils/types/event';
import { BaseOutcome } from 'src/utils/types/outcome';
import { IPagination } from 'src/utils/types/response';
import { SportTypeEnum } from 'src/utils/types/sport';
import { TypeSport } from '../types/sportsbook';

export const filterBetUTopPick = (data?: ICategory[]) => {
  const _leagues = _.orderBy(data, [(o) => o.category ?? o.categoryName]);
  const topPicks: ICategory[] = [];

  for (const obj of _leagues ?? []) {
    const eventsOrOutright = obj.tournaments ?? obj.events ?? [];

    const topPickCategories = eventsOrOutright.filter(
      (eventOrOutright) => eventOrOutright.isTopPick,
    );

    if (!_.isEmpty(topPickCategories)) {
      const topPickTourOrEvent = topPickCategories.filter(
        (tour) => tour.isTopPick,
      );

      topPicks.push({
        ...obj,
        tournaments: topPickTourOrEvent as ITournament[],
        events: topPickTourOrEvent as BaseEvent<BaseOutcome>[],
      });
    }
  }

  return { topPicks, leagues: _leagues };
};

export const toTournaments = (data?: IPagination<BaseEvent>[]) => {
  return _.chain(data || [])
    .flatMap((o) => o.items)
    .reduce((group, curr) => {
      const last = _.last(group);

      if (last && last.tournamentId === curr.tournamentId) {
        last.__events?.push(curr);
      } else {
        group.push({
          name: curr.tournament?.name,
          _id: curr.tournament?._id || '',
          tournamentId: curr.tournamentId,
          __events: [curr],
          sportId: curr.sportId,
          categoryId: curr.categoryId,
          isTopPick: curr.isTopPick,
          priorityInCategory: curr.priorityInCategory,
        });
      }

      return group;
    }, [] as ITournament[])
    .value();
};

const DISPLAY_REMAINING_TIME_SPORT_ID = [
  'sr:sport:2', //BasketBall
  'sr:sport:4', //iceHockey
  'sr:sport:16', // american football
];

const MATCH_TIME_SPORT_ID = [
  'sr:sport:1', //soccer
  'sr:sport:12', //Rugby
];

export const displayTime = (
  sportId: string | undefined,
  clock: SportEvent.Clock | undefined,
) => {
  if (!sportId) return '';
  if (MATCH_TIME_SPORT_ID.includes(sportId)) {
    const matchTime = clock?._match_time && `- ${clock?._match_time}`;
    return matchTime;
  }

  if (DISPLAY_REMAINING_TIME_SPORT_ID.includes(sportId)) {
    const remain =
      clock?._remaining_time_in_period &&
      `- ${clock?._remaining_time_in_period}`;
    return remain;
  }
  return '';
};

export const checkAccountMarketing = (profile: ProfileData | null) => {
  return (
    profile &&
    profile?.role === 0 &&
    profile.email.includes('marketingbetu@betu.io')
  );
};

export const toEventSlug = (event: BaseEvent) => {
  const a = _.chain([event.competitor1, event.competitor2, event.eventName])
    .compact()
    .join(' ')
    .kebabCase()
    .value();

  return `${a}-${_.replace(event.eventId, /\:/g, '_')}`;
};

export const parseEventSlug = (slug?: string) => {
  const eventId = _.chain(slug) // rcd-espanyol-barcelona-srl-werder-bremen-srl-sr_match_41530281
    .split('-') // [..., 'sr_match_41530281']
    .last() // it can be 'sr_match_41530281' or 'sr_simple_tournament_1234567'
    .split(/\_(.+)\_/g) // [sr, match, 41530281] | [sr, simple_tournament, 1234567]
    .join(':') // sr:match:41530281 | sr:simple_tournament:1234567
    .value();

  return eventId;
};

export const getTypeSport = (sportName: string) => {
  if (!sportName) return undefined;
  if (sportName === SportTypeEnum.Sport) return TypeSport.SPORT;
  if (sportName === SportTypeEnum.ESport) return TypeSport.ESPORT;
  return undefined;
};
