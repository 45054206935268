import _ from 'lodash';
import { MAX_NUMBER_OF_LEADERBOARD_ROWS } from 'src/utils/constants/common-constant';
import { getBRLocale } from 'src/utils/helpers/i18n';
import { request } from 'src/utils/request';
import {
  BetGame,
  DialogBetData,
  DialogBetRequest,
  DialogBetResponse,
  MyBet,
  MyBetRequest,
  MyBetStatus,
} from 'src/utils/types/my-bet-type';
import {
  BaseResponse,
  IPagination,
  PaginationResponse,
} from 'src/utils/types/response';
import {
  ELatestBetType,
  ILanguageBet,
  LatestBetMessage,
} from 'src/utils/types/socket-type';

export const getDialogBet = async (params: DialogBetRequest) => {
  const lang = getBRLocale(params?.lang);
  const {
    data: { data },
  } = await request.get<DialogBetResponse>(
    `/room-symbol-module/get-detail-multi-game`,
    {
      params: { ...params, lang },
    },
  );
  return convertDialogBet(data, lang);
};

export const getActiveBoard = async (locale?: string) => {
  const lang = getBRLocale(locale);

  const {
    data: { data },
  } = await request.get<BaseResponse<LatestBetMessage[]>>(
    `/room-symbol-module/get-activities-board`,
    {
      params: {
        lang,
        status_in: [
          MyBetStatus.LIVE,
          MyBetStatus.UPCOMING,
          MyBetStatus.WIN,
          MyBetStatus.WON,
          MyBetStatus.LOST,
          MyBetStatus.VOID,
          MyBetStatus.LOST_VOID_1,
          MyBetStatus.CASHOUT,
          MyBetStatus.WIN_VOID_0_5,
          MyBetStatus.LOST_VOID_0_5,
        ],
      },
    },
  );

  return convertActiveBoard(data, lang);
};

export const getMyBet = async (body: MyBetRequest) => {
  const locale = getBRLocale(body.lang);
  const params = {
    ...body,
    status: body.status === 4 ? undefined : body.status,
    lang: locale,
  };

  const { data } = await request.get<PaginationResponse<MyBet>>(
    `/room-symbol-module/get-my-bet`,
    { params: params },
  );
  return convertData(data.data, locale);
};

const convertData = (data: IPagination<MyBet>, locale: string) => {
  return {
    ...data,
    items: _.map(data.items, (o) => {
      const languages = _.get(o.betGame, ['languages', locale]);
      return {
        ...o,
        betGame: {
          ..._.omit(o.betGame, 'languages'),
          competitor1: _.get(
            languages,
            'competitor1',
            _.get(o, ['betGame', 'competitor1']),
          ),
          competitor2: _.get(
            languages,
            'competitor2',
            _.get(o, ['betGame', 'competitor2']),
          ),
          name: _.get(languages, 'name', _.get(o, ['betGame', 'name'])),
          marketOutcomes: {
            marketName: _.get(
              languages,
              ['marketOutcomes', 'marketName'],
              _.get(o, ['betGame', 'marketOutcomes', 'marketName']),
            ),
            outcomeName: _.get(
              languages,
              ['marketOutcomes', 'outcomeName'],
              _.get(o, ['betGame', 'marketOutcomes', 'outcomeName']),
            ),
            outcomeGroupName: _.get(
              languages,
              ['marketOutcomes', 'outcomeGroupName'],
              _.get(o, ['betGame', 'marketOutcomes', 'outcomeGroupName']),
            ),
          },
          sport: {
            name: _.get(
              languages,
              ['sport', 'name'],
              _.get(o, ['betGame', 'sport', 'name']),
            ),
          },
        },
      };
    }),
  } as IPagination<MyBet>;
};

const getEvent = (betItem?: LatestBetMessage, lang?: ILanguageBet) => {
  const { type, isOutRight } = betItem || {};
  const isMulti = type === ELatestBetType.MULTI;

  if (isMulti) {
    return `Multi (${betItem?.totalGames ?? 0})`;
  }

  if (isOutRight) {
    return betItem?.name;
  }

  const a = lang?.competitor1 ?? betItem?.competitor1;
  const b = lang?.competitor2 ?? betItem?.competitor2;
  return `${a} - ${b}`;
};

export const convertActiveBoard = (data: LatestBetMessage[], lang?: string) => {
  if (!lang) return data;

  return _.take(data, MAX_NUMBER_OF_LEADERBOARD_ROWS).map((el) => {
    const { languages, ...withoutLang } = el;

    const language = languages?.[lang];
    return {
      ...withoutLang,
      event: getEvent(el, language),
      competitor1: language?.competitor1 ?? withoutLang?.competitor1,
      competitor2: language?.competitor2 ?? withoutLang?.competitor2,
      sport: { ...el.sport, ...language?.sport },
    } as LatestBetMessage;
  });
};

const convertDialogBet = (_data: DialogBetData, lang?: string) => {
  const { items } = _data;

  if (!lang) return _data;
  const result: DialogBetData = {
    ..._data,
    items: _.map(items, (el): BetGame => {
      const languages = _.get(el, ['languages', lang]);
      const eventName = _.get(el, ['isOutRight'], false)
        ? _.get(languages, 'name', _.get(el, 'name'))
        : `${_.get(languages, 'competitor1', _.get(el, 'competitor1'))}-${_.get(
            languages,
            'competitor2',
            _.get(el, 'competitor2'),
          )}`;
      return {
        ..._.omit(el, 'languages'),
        eventName: eventName ?? el.eventName,
        competitor1: _.get(languages, 'competitor1', _.get(el, 'competitor1')),
        competitor2: _.get(languages, 'competitor2', _.get(el, 'competitor2')),
        sport: {
          ...el.sport,
          color: _.get(
            languages,
            ['sport', 'color'],
            _.get(el, ['sport', 'color']),
          ),
          logo: _.get(
            languages,
            ['sport', 'logo'],
            _.get(el, ['sport', 'logo']),
          ),
          name: _.get(
            languages,
            ['sport', 'name'],
            _.get(el, ['sport', 'name']),
          ),
        },
        marketOutcomes: {
          marketName: _.get(
            languages,
            ['marketOutcomes', 'marketName'],
            _.get(el, ['marketOutcomes', 'marketName'], ''),
          ),
          outcomeName: _.get(
            languages,
            ['marketOutcomes', 'outcomeName'],
            _.get(el, ['marketOutcomes', 'outcomeName'], ''),
          ),
          outcomeGroupName: _.get(
            languages,
            ['marketOutcomes', 'outcomeGroupName'],
            _.get(el, ['marketOutcomes', 'outcomeGroupName'], ''),
          ),
        },
      };
    }),
  };
  return result;
};
