import { Trans } from '@lingui/macro';
import { useAtomValue } from 'jotai';
import _ from 'lodash';
import { getMyBet } from 'src/api/sportbook-v2/ticket';
import { useSocketEventFn } from 'src/hooks/socket/use-socket-fn';
import { useLocale, useMyRouter } from 'src/hooks/use-locale';
import { useQuery } from 'src/hooks/use-query';
import { loggedAtom } from 'src/store/auth';
import { SocketEvent } from 'src/utils/constants/socket-event';
import { getMyBetKey } from 'src/utils/helpers/swr';
import { MyBetRequest } from 'src/utils/types/my-bet-type';
import { MyBetMessage, RoomType } from 'src/utils/types/socket-type';
import useSWR from 'swr';

export enum MyBetTab {
  ACTIVE = 1,
  SETTLED = 2,
  ALL = 4,
}

export const GET_DATA_TABS = (all = true) => {
  const statuses = [
    {
      query: 'active',
      label: <Trans id="common.active">Active</Trans>,
      value: MyBetTab.ACTIVE,
      emptyMsg: <Trans id="my_bets.no_active">No Active Bets.</Trans>,
    },
    {
      query: 'settled',
      label: <Trans id="common.settled">Settled</Trans>,
      value: MyBetTab.SETTLED,
      emptyMsg: <Trans id="my_bets.no_settled">No Settled Bets.</Trans>,
    },
  ];

  if (all) {
    return [
      {
        query: 'all',
        label: <Trans id="common.all">All</Trans>,
        value: MyBetTab.ALL,
        emptyMsg: <Trans id="my_bets.no_found">No Bets Found.</Trans>,
      },
      ...statuses,
    ];
  }

  return statuses;
};

export const FILTER_QUERY_KEY = 'filter';
const PAGE_QUERY_KEY = 'page';

export const useFetchMyBet = () => {
  const router = useMyRouter();

  const queries = useQuery([PAGE_QUERY_KEY, FILTER_QUERY_KEY]);

  const rawPage = Number(_.get(queries, [PAGE_QUERY_KEY], '1'));
  const rawStatus = _.get(queries, [FILTER_QUERY_KEY], 'all');

  const page = _.isNaN(rawPage) ? 1 : rawPage;
  const tabs = GET_DATA_TABS();
  const status = _.find(tabs, { query: rawStatus }) ?? tabs[0];

  const { data, isLoading } = useMyBets({
    page,
    status: status.value,
  });

  const handleChangePage = (_page: number | string) => {
    router.push(router.pathname, {
      query: {
        ...queries,
        [PAGE_QUERY_KEY]: _page,
        [FILTER_QUERY_KEY]: status.query,
      },
    });
  };

  return {
    tabs,
    loading: isLoading,
    data,
    page,
    currentTab: status,
    handleChangePage,
  };
};

export const useMyBets = (params: Pick<MyBetRequest, 'page' | 'status'>) => {
  const { locale } = useLocale();
  const logged = useAtomValue(loggedAtom);
  const { data, isLoading, mutate } = useSWR(
    logged ? getMyBetKey({ ...params, size: 6, lang: locale }) : null,
    ([key, params]) => getMyBet(params),
  );

  useSocketEventFn(SocketEvent.MyBets, (msg: MyBetMessage) => {
    if (![RoomType.SYMBOL, RoomType.PROMOTION_CAMPAIGN].includes(msg.roomType))
      return;

    mutate(
      (prev) => {
        if (!prev) return;
        return {
          ...prev,
          items: prev?.items.map((b) => {
            if (b.id === msg.id) return { ...b, ...msg };

            return b;
          }),
        };
      },
      { revalidate: true },
    );
  });

  return {
    data,
    isLoading,
  };
};

export const useActiveBetCounter = () => {
  const logged = useAtomValue(loggedAtom);
  const { data, mutate } = useSWR(
    logged ? { page: 1, size: 6, status: MyBetTab.ACTIVE } : null,
    getMyBet,
  );

  useSocketEventFn(SocketEvent.MyBets, () => {
    mutate(undefined, { revalidate: true });
  });

  return Number(data?.meta?.total_count ?? 0);
};
