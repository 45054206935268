import { tableCellClasses } from '@mui/material/TableCell';
import { makeStyles } from 'tss-react/mui';

type Props = {
  isCanceled: boolean;
};

export const useStyles = makeStyles<Props>()((theme, { isCanceled }) => {
  return {
    rootRow: {
      [`& .${tableCellClasses.root}`]: {
        padding: theme.spacing(2, 2),
        boxSizing: 'border-box',
        color: isCanceled ? theme.custom.content[3] : theme.custom.content[2],
      },
    },
    matchInfor: {
      display: 'flex',
      flexDirection: 'column',
    },
    inlineCompetitor: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    marginV4: {
      margin: theme.spacing(0.5, 0),
    },
    marginL4: {
      marginLeft: theme.spacing(0.5),
    },
    fontBold: {
      fontWeight: 700,
      color: isCanceled ? 'inherit' : theme.custom.content[1],
    },
    teams: {
      fontWeight: 700,
      color: isCanceled ? 'inherit' : theme.custom.content[2],
    },
    lastColumn: {
      width: 100,
    },
    status: {
      fontWeight: 'bold',
      fontSize: 12,
      textTransform: 'uppercase',
      padding: theme.spacing(0.375, 0.625),
      borderRadius: 3,
      lineHeight: 'normal',
      width: 'fit-content',
      letterSpacing: 0.4,
    },
    opacity: {
      '& img': {
        opacity: 0.3,
      },
    },
    normal: {
      '& img': {
        opacity: 0.5,
      },
    },
  };
});
