import { BigNumber } from 'bignumber.js';
import { useAtomValue } from 'jotai';
import _ from 'lodash';
import { oddsFormatAtom } from 'src/store/layout';
import { OddsFormat } from 'src/utils/constants/common-constant';
import { MAX_DENOMINATOR } from 'src/utils/constants/keys';
import { NotRoundBN } from 'src/utils/helpers/big-number';
import { useLocale } from './use-locale';

// chia ra thành 2 hooks để hiệu năng nhanh hơn,
// tránh destructure obj nhiều
// 1 cái để format
export const useOddsFormat = (odds: BigNumber.Value) => {
  const oddsFormat = useAtomValue(oddsFormatAtom);
  const { decimalSeparator } = useLocale();
  const asBN = new NotRoundBN(odds);

  if (oddsFormat === OddsFormat.Fraction) {
    const fraction = asBN.minus(1);
    if (fraction.isNaN()) return '0 / 1';
    const [odds1, odds2] = fraction.toFraction(MAX_DENOMINATOR);

    return fraction.isLessThanOrEqualTo(-1)
      ? '0 / 1'
      : `${odds1.toString()} / ${odds2.toString()}`;
  }
  if (oddsFormat === OddsFormat.American) {
    if (asBN.eq(0)) return '0';
    const _odds = asBN.minus(1);

    if (asBN.lt(2)) {
      // auto rounding
      return '-' + new NotRoundBN(100).div(_odds).abs().toFixed(0);
    } else {
      return '+' + _odds.multipliedBy(100).toFixed(0);
    }
  }

  // Decimal
  // we count decimal place for formatting odds, with dp should be in range [2,3]
  // 2 -> 2.00
  // 2.1 -> 2.10
  // 2.54 -> 2.54
  // 2.333 -> 2.333
  const dp = Math.max(
    _.chain(String(odds)).split('.').last().value()?.length ?? 2,
    2,
  );
  return asBN.toFormat(dp, { decimalSeparator });
};
