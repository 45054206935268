'use client';

import { Link as MuiLink, type LinkProps as MuiLinkProps } from '@mui/material';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import * as React from 'react';
import { useLocale } from 'src/hooks/use-locale';
import { localizePathname } from 'src/utils/constants/path';

type NextComposedProps = Omit<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  'href'
> &
  NextLinkProps;

const NextComposed = React.forwardRef<HTMLAnchorElement, NextComposedProps>(
  function NextComposedInner(props, ref) {
    const { as, href, replace, scroll, passHref, shallow, ...other } = props;

    return (
      <NextLink
        href={href}
        as={as}
        replace={replace}
        scroll={scroll}
        shallow={shallow}
        passHref={passHref}
        ref={ref}
        {...other}
        prefetch={false}
      />
    );
  },
);

interface LinkPropsBase {
  innerRef?: React.Ref<HTMLAnchorElement>;
  noLinkStyle?: boolean;
}

export type MyLinkProps = LinkPropsBase &
  NextComposedProps &
  Omit<MuiLinkProps, 'href' | 'variant'>;

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/#with-link
const BaseLink: React.FCC<MyLinkProps> = (props) => {
  const { href, innerRef, noLinkStyle, ...other } = props;
  const { locale } = useLocale();

  const localizedHref =
    typeof href === 'string'
      ? localizePathname({ href, locale })
      : href?.pathname
        ? {
            ...href,
            pathname: localizePathname({ href: href.pathname, locale }),
          }
        : href;

  if (!localizedHref) return null;

  if (noLinkStyle) {
    return <NextComposed ref={innerRef} href={localizedHref} {...other} />;
  }

  return (
    <MuiLink
      component={NextComposed}
      ref={innerRef}
      href={localizedHref}
      {...other}
    />
  );
};

export const MyLink = React.forwardRef<HTMLAnchorElement, MyLinkProps>(
  function LinkInner(props, ref) {
    return <BaseLink {...props} innerRef={ref} />;
  },
);
