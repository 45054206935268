import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';
import _ from 'lodash';
import { FCC } from 'react';
import { useOddsFormat } from 'src/hooks/use-odds-format';
import { toStartCase } from 'src/utils/helpers/fixture';
import {
  showBetStatusName,
  showBetStatusStyle,
} from 'src/utils/helpers/format-bet';
import { convertTimeBetISO } from 'src/utils/helpers/time';
import { BetGame, MyBetStatus } from 'src/utils/types/my-bet-type';
import { useStyles } from './styles';

interface RowProps {
  data: BetGame;
}

export const MobileDialogBet: FCC<RowProps> = (props) => {
  const { data } = props;

  const isBlur =
    data.status === MyBetStatus.CANCELED ||
    data.status === MyBetStatus.LOST_VOID_1 ||
    data.status === MyBetStatus.VOID;
  const { classes, cx } = useStyles({ isCanceled: isBlur });

  const odds = useOddsFormat(data.placeOdd);

  return (
    <div className={classes.BoxMobile}>
      <div className={classes.status} style={showBetStatusStyle(data.status)}>
        {showBetStatusName(data.status)}
      </div>
      <div className={classes.marginT8}>
        <Typography color="custom.content.2">
          <Trans id="sportsbook.match_info">Match Info</Trans>
        </Typography>
        <div className={classes.inforCompetitor}>
          <img src={data?.sport?.logo} width={16} height={16} alt="logo" />
          <Typography variant="body2">{data.eventName}</Typography>
        </div>

        {!data.isOutRight && data.startTime && (
          <Typography variant="body2">
            <Typography variant="body2">
              {convertTimeBetISO(data.startTime)}
            </Typography>
          </Typography>
        )}
      </div>
      <div className={classes.marginT24}>
        <Typography color="custom.content.2">
          <Trans id="sportsbook.selection">Selection</Trans>
        </Typography>

        <div className={cx(classes.marginT8)}>
          <Typography variant="body2" className={classes.fontBold}>
            {toStartCase(
              _.get(data, ['marketOutcomes', 'outcomeGroupName'], ''),
              _.get(data, ['marketOutcomes', 'outcomeName'], ''),
            )}
          </Typography>
          <Typography>
            {toStartCase(_.get(data, ['marketOutcomes', 'marketName'], ''))}
          </Typography>
        </div>
      </div>

      <div className={classes.marginT24}>
        <Typography color="custom.content.2">
          <Trans id="sportsbook.odds">Odds</Trans>
        </Typography>
        <Typography
          variant="body2"
          className={cx(classes.marginT8, classes.fontBold)}>
          {odds}
        </Typography>
      </div>
    </div>
  );
};
