import { atom } from 'jotai';
import {
  BannerCarouselFragmentFragment,
  ListItemFragmentFragment,
} from 'src/api/contentful/gen/graphql';
import LogoSvg from 'src/assets/images/logo.svg?url';

export const legalsAtom = atom<ListItemFragmentFragment[]>([]);
export const socialMediasAtom = atom<ListItemFragmentFragment[]>([]);
export const logoAtom = atom<string>(LogoSvg.src);
export const bannerCarouselAtom = atom<
  Record<string, BannerCarouselFragmentFragment[]>
>({});
