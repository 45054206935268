'use client';

import _ from 'lodash';
import { useParams, useSearchParams } from 'next/navigation';

export function useQuery<Key extends string>(keys: Key | Key[]) {
  const _keys = _.isArray(keys) ? keys : [keys];

  const query = useSearchParams();
  const params = useParams();

  const res = {} as Record<Key, string>;

  for (const k of _keys) {
    const val = params[k] ?? query.get(k);
    if (!val) continue;

    const v = _.isArray(val) ? val[0] : val;
    _.set(res, k, decodeURIComponent(v));
  }

  return res;
}
