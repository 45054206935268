import { atom } from 'jotai';
import { atomEffect } from 'jotai-effect';
import { type Socket, io } from 'socket.io-client';
import { type SocketEvent } from 'src/utils/constants/socket-event';

type UnregisterFunc = () => void;
type CallbackFunc = (...args: any[]) => any;

const _socketAtom = atom<Socket | null>(null);

export const connectSocketAtom = atomEffect((get, set) => {
  const instance = io(process.env.NEXT_PUBLIC_SOCKET_URL ?? '', {
    closeOnBeforeunload: true,
    transports: ['websocket'],
    autoConnect: false,
  });

  instance
    .connect()
    .on('connect', () => {
      set(_socketAtom, instance);
    })
    .on('disconnect', () => {
      set(_socketAtom, null);
    });
});

export const socketAtom = atom(
  (get) => get(_socketAtom),
  (get, set, forEvent: SocketEvent, cb: CallbackFunc) => {
    const socket = get(_socketAtom);
    if (!socket) return;
    socket.on(forEvent, cb);
    const cleanup: UnregisterFunc = () => {
      socket.off(forEvent, cb);
    };

    return cleanup;
  },
);
