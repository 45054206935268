import { TableCell, TableRow, Typography } from '@mui/material';
import _ from 'lodash';
import { FCC } from 'react';
import { useOddsFormat } from 'src/hooks/use-odds-format';
import { toStartCase } from 'src/utils/helpers/fixture';
import {
  showBetStatusName,
  showBetStatusStyle,
} from 'src/utils/helpers/format-bet';
import { convertTimeBetISO } from 'src/utils/helpers/time';
import { BetGame, MyBetStatus } from 'src/utils/types/my-bet-type';
import { useStyles } from './styles';

interface RowProps {
  data: BetGame;
}

export const RowDialogBet: FCC<RowProps> = (props) => {
  const { data } = props;
  const isBlur =
    data.status === MyBetStatus.CANCELED ||
    data.status === MyBetStatus.LOST_VOID_1 ||
    data.status === MyBetStatus.VOID;

  const { classes, cx } = useStyles({ isCanceled: isBlur });

  const odds = useOddsFormat(data.placeOdd);

  return (
    <TableRow className={classes.rootRow}>
      <TableCell colSpan={5}>
        <div className={classes.matchInfor}>
          <div
            className={cx(
              classes.inlineCompetitor,
              classes.marginV4,
              isBlur ? classes.opacity : classes.normal,
            )}>
            <img src={data?.sport?.logo} width={16} height={16} alt="logo" />
            <Typography variant="body2" className={classes.marginL4}>
              {data.eventName ?? ''}
            </Typography>
          </div>

          {!data.isOutRight && data.startTime && (
            <Typography variant="body2">
              {convertTimeBetISO(data.startTime)}
            </Typography>
          )}
        </div>
      </TableCell>
      <TableCell colSpan={2}>
        <div className={classes.matchInfor}>
          <Typography variant="body2" className={classes.fontBold}>
            {toStartCase(
              _.get(data, ['marketOutcomes', 'outcomeGroupName'], ''),
              _.get(data, ['marketOutcomes', 'outcomeName'], ''),
            )}
          </Typography>
          <Typography>
            {toStartCase(_.get(data, ['marketOutcomes', 'marketName'], ''))}
          </Typography>
        </div>
      </TableCell>
      <TableCell colSpan={2}>
        <Typography variant="body2" className={classes.fontBold}>
          {odds}
        </Typography>
      </TableCell>
      <TableCell className={classes.lastColumn}>
        <div className={classes.status} style={showBetStatusStyle(data.status)}>
          {/* // fix hien thi status LOST_VOID_1 */}
          {showBetStatusName(data.status)}
        </div>
      </TableCell>
    </TableRow>
  );
};
