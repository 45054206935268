import { Trans } from '@lingui/macro';
import _ from 'lodash';
import {
  BET_PAYOUT,
  BET_STATUS_STYLES,
  getBetName,
} from '../constants/bet-constant';
import { MyBetStatus } from '../types/my-bet-type';

export const showBetStatusStyle = (_status?: string) => {
  const status = _.toUpper(_status);

  return _.get(BET_STATUS_STYLES, [status], BET_STATUS_STYLES['UPCOMING']);
};

export const showStatusPayout = (status?: string) => {
  if (status === MyBetStatus.CREATED || status === MyBetStatus.UPCOMING)
    return <Trans id="sportsbook.est_payout">Est. Payout</Trans>;

  return <Trans id="common.payout">Payout</Trans>;
};

export const formatPayout = (payout?: string, status?: string) => {
  const formatted = payout ?? '0';

  if (status === MyBetStatus.CREATED || status === MyBetStatus.UPCOMING) {
    return { color: BET_PAYOUT.default, payout: formatted };
  }

  if (+formatted > 0) {
    const green = BET_PAYOUT[MyBetStatus.WON];

    return { color: green, payout: formatted };
  }

  if (+formatted <= 0) {
    const red = BET_PAYOUT[MyBetStatus.LOST];

    return { color: red, payout: formatted };
  }

  return { color: BET_PAYOUT.default, payout: formatted };
};
export const showBetStatusName = (status?: string) => {
  const _status = _.toUpper(status);

  return _.get(getBetName(), [_status], _status);
};
